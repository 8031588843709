import React, { FC } from 'react';
import styled from 'styled-components';

import BannerOne from '../BannerOne';
import { mobile, smMobile, tablet } from 'styles/breakpoints';
import PaymentSteps from 'pages/start/payments/components/PaymentSteps';
import List from '../List';
import Disclaimer from '../Disclaimer';

interface LifetimeProps {
  hasEbookOrPaperback?: any;
}

const PaymentStepsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  width: 100%;
  background-color: #f6f4ef;
  @media ${mobile} {
    padding: 1.5rem 2.125rem;
  }
  @media ${smMobile} {
    padding: 1.5rem 0.75rem;
  }
`;

const BotContainer = styled.div`
  width: 100%;
  background-color: #f6f4ef;
  display: flex;
  justify-content: center;
`;

const PaymentStepsStyled = styled(PaymentSteps)`
  max-width: 56.25rem;
  width: 100%;
`;
const ListStyled = styled(List)`
  max-width: 25.5rem;
`;

const TopTextContainer = styled.div`
  background-color: #f6f4ef;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Headline = styled.p`
  color: #1c1c28;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 1rem;
  max-width: 48rem;
  @media ${tablet} {
    max-width: 21.4375rem;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
`;

const Label = styled.p`
  color: #28293d;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-bottom: 2.25rem;
  text-align: center;
  max-width: 48rem;
  @media ${tablet} {
    max-width: 21.4375rem;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
`;

const Lifetime: FC<LifetimeProps> = ({
  bannerOne,
  paymentSteps,
  headline,
  label,
  planSection,
  PlanSectionComponent,
  list,
  disclaimer,
  userHasBoughtPaperbackBook,
  bannerTexts,
  hasEbookOrPaperback,
}) => (
  <>
    {!bannerTexts && hasEbookOrPaperback && (
      <BannerOne {...bannerOne} boughtPaperback={userHasBoughtPaperbackBook} />
    )}
    <PaymentStepsContainer>
      <PaymentStepsStyled
        {...paymentSteps}
        hasOptionalStep={userHasBoughtPaperbackBook}
      />
    </PaymentStepsContainer>
    <TopTextContainer>
      <Headline>{headline}</Headline>
      <Label>{label}</Label>
    </TopTextContainer>
    <PlanSectionComponent {...planSection} />
    <BotContainer>
      <ListStyled {...list} />
    </BotContainer>
    <Disclaimer {...disclaimer} />
  </>
);

export default Lifetime;
